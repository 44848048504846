import curl from "highlightjs-curl";
import { Light as SyntaxHighlighter, SyntaxHighlighterProps } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import yaml from "react-syntax-highlighter/dist/cjs/languages/hljs/yaml";
import markdown from "react-syntax-highlighter/dist/cjs/languages/hljs/markdown";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import typescript from "react-syntax-highlighter/dist/cjs/languages/hljs/typescript";
import javascript from "react-syntax-highlighter/dist/cjs/languages/hljs/javascript";
import plaintext from "react-syntax-highlighter/dist/cjs/languages/hljs/plaintext";
import lightTheme from "react-syntax-highlighter/dist/cjs/styles/hljs/atom-one-light";
import hljs from "highlight.js/lib/core";
import { startCase } from "lodash";

hljs.registerLanguage("typescript", typescript);
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("python", python);
hljs.registerLanguage("json", json);
hljs.registerLanguage("yaml", yaml);
hljs.registerLanguage("curl", curl);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("markdown", markdown);
hljs.registerLanguage("plaintext", plaintext);

SyntaxHighlighter.registerLanguage("typescript", typescript);
SyntaxHighlighter.registerLanguage("javascript", typescript); // Using typescript styles here as they seem identical.
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("yaml", yaml);
SyntaxHighlighter.registerLanguage("curl", curl);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("markdown", markdown);
SyntaxHighlighter.registerLanguage("plaintext", plaintext);

export const LANGUAGES = [
  "python",
  "json",
  "json-collapsible",
  "typescript",
  "javascript",
  "yaml",
  "curl",
  "bash",
  "markdown",
  "plaintext",
] as const;

export type Language = (typeof LANGUAGES)[number];

export const renderLanguageName = (language: Language) => {
  switch (language) {
    case "python":
      return "Python";
    case "json":
      return "JSON";
    case "json-collapsible":
      return "Tree";
    case "yaml":
      return "YAML";
    case "typescript":
      return "TypeScript";
    case "javascript":
      return "JavaScript";
    case "curl":
      return "Curl";
    case "bash":
      return "Bash";
    case "markdown":
      return "Markdown";
    case "plaintext":
      return "Text";
    default:
      return startCase(language);
  }
};

type LanguageDetectionResult = {
  language: Language;
  relevance: number;
  secondBest?: {
    language: Language;
    relevance: number;
  };
};
export const detectLanguage = (code: string): LanguageDetectionResult => {
  const result = hljs.highlightAuto(code, [...LANGUAGES]); // Have to spread to appease TypeScript.
  const { language, relevance, secondBest } = result;

  return {
    language: language as Language,
    relevance,
    secondBest: secondBest
      ? {
          language: secondBest.language as Language,
          relevance: secondBest.relevance,
        }
      : undefined,
  };
};

interface Props extends SyntaxHighlighterProps {
  language: Language;
  noPadding?: boolean;
}

const CodePreview = ({ language, noPadding, ...props }: Props) => {
  return (
    <SyntaxHighlighter
      {...props}
      language={language}
      style={lightTheme}
      customStyle={{
        backgroundColor: "transparent",
        paddingLeft: noPadding ? undefined : 8,
        paddingRight: noPadding ? undefined : 8,
        paddingBlockStart: noPadding ? 0 : undefined,
        paddingBlockEnd: noPadding ? 0 : undefined,
        lineHeight: 1.5,
        ...props.customStyle,
      }}
    >
      {props.children}
    </SyntaxHighlighter>
  );
};

export default CodePreview;
