import { downloadFile } from "@/lib/utils";
import { EvaluatorArgumentsType, EvaluatorReturnType, EvaluatorType } from "@/types/app/evaluator";
import { PromptKernelRequest, ResponseFormat, TemplateLanguage } from "@/types/app/prompt";
import { hlToastApiError } from "@components/library/Toast";
import { AxiosPromise } from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { ApiService } from "./api.service";
import { ModelConfigRequest } from "./model-configs.service";
import { ChatMessage, ModelEndpoint, ModelProvider } from "./playground.service";
import { EditorTool, LinkedTool, ToolConfig, ToolResponse, toolResponsesToToolsAndLinkedTools } from "./v4.service";

export type ConfigType = "tool" | "agent" | "generic" | "evaluator" | "dataset" | "prompt";

// Extended for use in the frontend with examples and new projects.
export type ProjectType = ConfigType | "example" | null;

interface BaseConfigResponse {
  id: string;
  other?: Record<string, any>;
  type: ConfigType;
}

interface BaseOrganizationConfigResponse extends BaseConfigResponse {
  name: string;
  description?: string;
}

// TODO: Unify with ToolConfig in tools.service
export interface ToolConfigResponse extends BaseOrganizationConfigResponse {
  parameters: Record<string, any>;
  source_code: string;
  type: "tool";
}

// These currently use the request models in the backend. (And our frontend doesn't know about the request models yet.)
export type AgentToolConfigResponse = Omit<ToolConfigResponse, "id">;
export type AgentModelConfigResponse = Omit<ModelConfigResponse, "id">;

export interface AgentConfigResponse extends BaseOrganizationConfigResponse {
  agent_class: string;
  tools: AgentToolConfigResponse[];
  model_config: AgentModelConfigResponse;
  type: "agent";
}

export interface GenericConfigResponse extends BaseOrganizationConfigResponse {
  type: "generic";
}

export interface ModelConfigResponse extends Omit<ModelConfigRequest, "tools"> {
  name: string;
  id: string;
  type: "model";
  tool_configs: ToolConfig[];
  tools: ToolResponse[];
}

interface EvaluatorConfigResponse extends BaseOrganizationConfigResponse {
  evaluator_type: EvaluatorType;
  model_config: ModelConfigResponse | null;
  code: string | null;
  arguments_type: EvaluatorArgumentsType | null;
  return_type: EvaluatorReturnType | null;
  type: "evaluator";
}

export type ConfigResponse =
  | ModelConfigResponse
  | ToolConfigResponse
  | AgentConfigResponse
  | GenericConfigResponse
  | EvaluatorConfigResponse;

export interface ModelConfigEvaluatorAggregate {
  model_config_id: string;
  evaluator_id: string;
  evaluator_version_id: string;
  aggregate_value: number | null;
}

export interface ProjectConfigResponse {
  project_id?: string; // TODO: Optional on backend to avoid a join, but I think we should always have it.
  project_name?: string; // TODO: Optional on backend to avoid a join, but I think we should always have it.

  num_datapoints?: number;
  evaluation_aggregates?: ModelConfigEvaluatorAggregate[];

  created_at: string;
  updated_at: string;
  last_used: string; // Would be nice to use the _at suffix for consistency.

  config: ConfigResponse;
}

// The canonical version of ProjectConfigResponse used in the frontend.
export interface ProjectConfig extends Omit<ProjectConfigResponse, "created_at" | "updated_at" | "last_used"> {
  id: string; // This is for frontend convenience, as some places expect a type with an id. Parsed from `.config.id`.

  created_at: dayjs.Dayjs;
  updated_at: dayjs.Dayjs;
  last_used: dayjs.Dayjs;

  // Computed properties
}

// Parse ProjectConfigResponse and compute some useful properties.
export const parseProjectConfig = (config: ProjectConfigResponse): ProjectConfig => ({
  ...config,
  id: config.config.id,
  created_at: dayjs.utc(config.created_at).local(),
  updated_at: dayjs.utc(config.updated_at).local(),
  last_used: dayjs.utc(config.last_used).local(),
});

export interface SliderRange {
  min: number;
  max: number;
}

// Match with ModelConfigRequest in src/external/app/models/v3/model_configs.py
// TODO: move into configservice
// This should be unified with ModelConfigRequest in frontend/services/model-configs.service.ts
// This is kinda the PlaygroundModelConfig
// Meant to be the values that get hashed to define a new model config.
export interface ModelConfig {
  provider?: ModelProvider;
  endpoint?: ModelEndpoint;
  model?: string;
  prompt_template?: string;
  template_language?: TemplateLanguage | null;
  chat_template?: ChatMessage[];
  temperature?: number | null;
  max_tokens?: number | null;
  top_p?: number | null;
  stop?: string | string[] | null;
  presence_penalty?: number | null;
  frequency_penalty?: number | null;
  other?: Record<string, any> | null;
  tools?: (EditorTool | LinkedTool)[];
  // This should be a property of the model not a model config
  presence_penalty_range?: SliderRange;
  frequency_penalty_range?: SliderRange;
  seed?: number | null;
  response_format?: ResponseFormat | null;
  reasoning_effort?: "high" | "medium" | "low" | null;
}

export type ModelConfigWithPromptTools = Omit<ModelConfig, "tools"> &
  Pick<PromptKernelRequest, "tools" | "linked_tools">;

// Response also contains "description" which we don't use in the editor
export const modelConfigFromResponse = (config: ModelConfigResponse): ModelConfigWithPromptTools => {
  // When we save an inline tool it doesnt have an id, but when we load it it does. We need to do the mapping to remove that.
  // TODO - This brings the tool_config mapping complexity back into the code, remove and refactor when we can.
  // TODO: We might still need something like this to handle different in linked tools between prompt response and request, but
  // FYI this should be removed soon!
  const { tools, linkedTools } = toolResponsesToToolsAndLinkedTools(config.tools);
  return {
    ..._.omit(config, "tool_configs", "description"),
    tools: tools,
    linked_tools: linkedTools.map((tool) => tool.id),
  };
};

export const modelConfigToPrompt = (config: ModelConfigWithPromptTools): PromptKernelRequest => {
  return {
    model: config.model || "",
    endpoint: config.endpoint || "chat",
    template: config.prompt_template || config.chat_template || null,
    provider: config.provider || "openai",
    max_tokens: config.max_tokens,
    temperature: config.temperature,
    top_p: config.top_p,
    stop: config.stop,
    frequency_penalty: config.frequency_penalty,
    presence_penalty: config.presence_penalty,
    other: config.other,
    seed: config.seed,
    reasoning_effort: config.reasoning_effort,
    response_format: config.response_format,
    template_language: config.template_language,
    tools: config.tools,
    linked_tools: config.linked_tools,
    attributes: {
      /* any additional attributes */
    },
  };
};

// Raw response from backend
export interface ProjectModelConfigResponse extends ModelConfig {
  id: string;
  project_id?: string; //TODO: Optional on backend to avoid a join, but I think we should always have it.
  project_name?: string; //TODO: Optional on backend to avoid a join, but I think we should always have it.
  display_name?: string; //TODO: Optional, for AFICT, no good reason.

  // description?: string; // Not used and don't want it to be, so commented out.
  num_datapoints?: number;

  created_at: string;
  updated_at: string;
  last_used: string; // Would be nice to use the _at suffix for consistency.
}

// The canonical entity we use in the frontend.
// Why is this still here? Should this now be a ProjectConfig?
export interface ProjectModelConfig
  extends Omit<ProjectModelConfigResponse, "created_at" | "updated_at" | "last_used"> {
  created_at: dayjs.Dayjs;
  updated_at: dayjs.Dayjs;
  last_used: dayjs.Dayjs;
}

export const serializeModelConfig = (modelConfig: ModelConfig): AxiosPromise<string> => {
  return ApiService.post("/v4/model-configs/serialize", modelConfig);
};

export const deserializeModelConfig = (modelConfigString: string): AxiosPromise<ModelConfigResponse> => {
  return ApiService.post("/v4/model-configs/deserialize", { config: modelConfigString });
};

export const exportModelConfig = async (id: string): AxiosPromise<string> => {
  return ApiService.post(`/v4/model-configs/${id}/export`);
};

// TODO: Move to some utils file
export const downloadSerializedModelConfig = async ({
  fileStem,
  ...props
}: { fileStem: string } & ({ modelConfig: ModelConfig } | { modelConfigId: string })) => {
  try {
    const serializedModelConfig =
      "modelConfig" in props
        ? await serializeModelConfig(props.modelConfig)
        : await exportModelConfig(props.modelConfigId);
    downloadPromptFile({ prompt: serializedModelConfig.data, fileStem });
  } catch (error: any) {
    hlToastApiError({
      error,
      titleFallback: "Failed to export prompt",
    });
  }
};

export const downloadPromptFile = ({ prompt, fileStem }: { prompt: string; fileStem: string }) => {
  downloadFile(new Blob([prompt]), `${fileStem}.prompt`, "text/prompt");
};
